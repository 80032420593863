import http from "./httpService";
import apiUrl from "../config.json";
import authService from "./authService";

export function getAgencyID(sembraKey) {
    const apiEndpoint = apiUrl.apiUrl + "/getAgencyID";

    return http.post(apiEndpoint, {sembraKey:sembraKey}, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` } });
}

export function getAgencyData() {
    const apiEndpoint = apiUrl.apiUrl + "/getAgencyData";
    return http.post(apiEndpoint, {username:authService.getCurrentUser()}, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` } });
}

export function saveAgencyData(agencyID,saveData) {
    const apiEndpoint = apiUrl.apiUrl + "/saveAgencyData";
    
    return http.post(apiEndpoint, {id:agencyID,saveData:saveData}, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` } });
}

export function addValidAgencyUser(username, agencyID, userJWT) {
    const apiEndpoint = apiUrl.apiUrl + "/addValidAgencyUser";
    
    return http.post(apiEndpoint, {username:username,id:agencyID}, {
        headers: { Authorization: `Bearer ${userJWT}` } });
}

export default {
    getAgencyID,
    getAgencyData,
    saveAgencyData,
    addValidAgencyUser
}