import React, { useState } from "react";
import DocFill from "./doc-fill";
import VerificationMessage from "../../verification-message";
// import { getUserData } from "../../../services/authService";
import docService from "../../../services/docService";

export default function SignatureFunctionality({
  docId,
  userData,
  selectedDocument,
  onDocSign,
  docSigned,
  getSignedDoc,
  pdfDoc,
  docNamePretty,
  taxIDFullList
}) {
  const [sigErr, setSigErr] = useState(false);
  const [sigErrMessage, setSigErrMessage] = useState("Signature Error");
  const [resentDocMessage, setResentDocMessage] = useState("");
  // const [pdfDoc, setPdfDoc] = useState("");
  // console.log(taxIDFullList);
  const addSig = async () => {
    setResentDocMessage("");
    await docService.checkDoc(docId).then(async (docResponse) => {
      // console.log(docResponse);
      if (docResponse == "No Signature Found") {
        setSigErrMessage(docResponse);
        setSigErr(true);
      } else {
        setSigErr(false);
        // setDocumentSigned(true);
        onDocSign();
        const pdfReturn = await DocFill(
          selectedDocument,
          docResponse.docSignature,
          taxIDFullList
        );
        // setPdfDoc(pdfReturn);
        getSignedDoc(pdfReturn);
      }
    });
  };

  const resendDoc = async () => {
    // const userData = getUserData();
    // console.log(userData);
    await docService
      .resendDoc(
        docId,
        "Sembra Messenger Participtation Contract",
        userData.userData.mobile.replace(/\D/g, "")
      )
      .then(async (docResponse) => {
        setResentDocMessage(docResponse);
      });

    setSigErr(false);
  };

  return (
    <>
      {sigErr && (
        <VerificationMessage isSuccess={false} userMessage={sigErrMessage} />
      )}

      {!docSigned && (
        <>
          {/* <div className="user-message">
            <p>
              Apply signature to all documents functionality here? If legally
              permitted. ** Not functioning **
            </p>
            <label
              htmlFor="apply-sig-yes"
              name="apply-sig"
              style={{ marginRight: "1rem" }}
            >
              <input id="apply-sig-yes" name="apply-sig" type="radio" />
              Yes
            </label>

            <label htmlFor="apply-sig-no" name="apply-sig">
              <input id="apply-sig-no" name="apply-sig" type="radio" />
              No
            </label>

            <hr />
          </div> */}
          <div className="doc-btn-box">
            <div className="btn-group">
              <p>After signing SembraMessenger Participation on your phone:</p>
              <button className="sc-btn" onClick={addSig}>
                Add Signature
              </button>
            </div>

            <div className="btn-group">
              <p>If you did not recieve a text:</p>
              <button className="sc-btn" onClick={resendDoc}>
                Resend {docNamePretty}
              </button>
            </div>
          </div>
        </>
      )}

      {/* {!docSigned ? (
        <div className="doc-btn-box">
          <div className="btn-group">
            <p>After signing SembraMessenger Participation on your phone:</p>
            <button className="sc-btn" onClick={addSig}>
              Add Signature
            </button>
          </div>
          <div className="btn-group">
            <p>If you did not recieve a text:</p>
            <button className="sc-btn" onClick={resendDoc}>
              Resend {docNamePretty}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p>Click the link below to download signed contract</p>
          <a download={docNamePretty} href={pdfDoc}>
            {selectedDocument.docTitle}
          </a>
        </div>
      )} */}
      {resentDocMessage !== "" && (
        <VerificationMessage
          isSuccessMessage={resentDocMessage === "Resent" ? true : false}
          userMessage={resentDocMessage}
        />
      )}
      {/* {resentDocMessage !== "" || resentDocMessage === "Resent" && (
        <VerificationMessage isSuccess={true} userMessage={resentDocMessage} />
      )} */}
    </>
  );
}
