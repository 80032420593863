import http from "./httpService";
import apiUrl from "../config.json"
import authService from "./authService";

export async function send2FA(phoneNumber, username, userJWT) {
    const apiEndpoint = apiUrl.apiUrl + "/send2FA";
    const phoneResponse = await http.post(apiEndpoint, { username: username.toLowerCase(), phoneNumber: phoneNumber.replace(/\D/g, "") }, {
        headers: { Authorization: `Bearer ${userJWT}` }
    });
    return phoneResponse;
}

export async function verifyPhone(username, code, userJWT) {
    const apiEndpoint = apiUrl.apiUrl + "/verifyPhone";
    const verificationResponse = await http.post(apiEndpoint, { username: username.toLowerCase(), code: code }, {
        headers: { Authorization: `Bearer ${userJWT}` }
    });
    return verificationResponse;
}

export async function sendForgotUser(phoneNumber) {
    const apiEndpoint = apiUrl.apiUrl + "/sendForgotUser";
    const phoneResponse = await http.post(apiEndpoint, { phoneNumber: phoneNumber });
    return phoneResponse;
}

export async function sendForgotPassword(phoneNumber, username) {
    const apiEndpoint = apiUrl.apiUrl + "/sendForgotPassword";
    const phoneResponse = await http.post(apiEndpoint, { phoneNumber: phoneNumber, username: username });
    return phoneResponse;
}

export async function verifyTemporaryPassword(code, username) {
    const apiEndpoint = apiUrl.apiUrl + "/verifyTemporaryPassword";
    const verificationResponse = await http.post(apiEndpoint, { code: code, username: username });
    return verificationResponse;
}

export async function resetPassword(newPassword, username) {
    const apiEndpoint = apiUrl.apiUrl + "/resetPassword";
    const verificationResponse = await http.post(apiEndpoint, { password: newPassword, username: username });
    return verificationResponse;
}

export default {
    send2FA,
    verifyPhone,
    sendForgotUser,
    sendForgotPassword,
    verifyTemporaryPassword,
    resetPassword
}