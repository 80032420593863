import React, { useState, useEffect } from "react";
import Modal from "../../modal";
import UserMessage from "../../user-message";
import AttnDocsModalBody from "./doc-modal";
import Button from "../../button";
import DocFill from "./doc-fill";
import messengerDoc from "../../../assets/pdfs/ParticipationAgreement.pdf";
import { FaUserCircle } from "react-icons/fa";
import { ImCheckmark } from "react-icons/im";
import TimedUserMessage from "../../timed-user-message";

export default function AttnDocs({
  agencyData,
  userData,
  attnDocs,
  handleUserUpdate,
  taxIDFullList,
  // handleTimedMessage,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});

  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [timedMessage, setTimedMessage] = useState("");
  // const [timedMessageType, setTimedMessageType] = useState("");
  const handleTimedMessage = (message, type) => {
    setTimedMessage(message);
    setIsMessageVisible(!isMessageVisible);
    // setTimedMessageType(type);
  };

  const handleModal = (selectedDoc) => {
    setShowModal(true);
    setSelectedDocument(selectedDoc);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const modalBody = (
    <AttnDocsModalBody
      agencyData={agencyData}
      selectedDocument={selectedDocument}
      closeModal={closeModal}
      handleUserUpdate={handleUserUpdate}
      userData={userData}
      handleTimedMessage={handleTimedMessage}
      taxIDFullList={taxIDFullList}
    />
  );
  const modalTitle = "Sign Documents";

  const [messengerParticipationSigned, setMessengerParticipationSigned] =
    useState(false);
  useEffect(() => {
    if (agencyData) {
      agencyData.map((agency) => {
        if (
          agency.messengerData &&
          agency.messengerData.participationDocSigned
        ) {
          setMessengerParticipationSigned(true);
        } else {
          setMessengerParticipationSigned(false);
        }
      });
    }
  });

  // Generate Sample Document
  const handleSamplePDF = async (doc) => {
    let sampleDoc = await DocFill(doc, "", taxIDFullList);
    var file_path = sampleDoc;
    var a = document.createElement("A");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      {/* <button onClick={() => handleTimedMessage("TEST")}>TEST</button> */}
      {attnDocs.length === 0 && (
        <TimedUserMessage
          message={timedMessage}
          variant="success"
          isVisible={isMessageVisible}
          hasIcon={true}
          icon={<ImCheckmark />}
        />
      )}
      {attnDocs.length > 0 ? (
        <div>
          {attnDocs.length == 1 ? (
            <UserMessage
              message={`${attnDocs.length} DOCUMENT NEEDS ATTENTION`}
              variant="error"
            />
          ) : (
            <UserMessage
              message={`${attnDocs.length} DOCUMENTS NEED ATTENTION`}
              variant="error"
            />
          )}

          <UserMessage
            message=" Please review each document before adding your signature."
            variant="information"
          />

          {/* <p>
            Apply signature to all documents functionality here? If legally
            permitted. ** Not functioning **
          </p>
          <label
            htmlFor="apply-sig-yes"
            name="apply-sig"
            style={{ marginRight: "1rem" }}
          >
            <input id="apply-sig-yes" name="apply-sig" type="radio" />
            Yes
          </label>

          <label htmlFor="apply-sig-no" name="apply-sig">
            <input id="apply-sig-no" name="apply-sig" type="radio" />
            No
          </label> */}

          <hr />
          <TimedUserMessage
            // type={timedMessageType}
            message={timedMessage}
            variant="success"
            isVisible={isMessageVisible}
            hasIcon={true}
            icon={<ImCheckmark />}
          />
          <ul className="no-list-style">
            {attnDocs.map((doc, index) => (
              <li key={index}>
                <div className="attn-docs">
                  <p>
                    <strong>Provider:</strong> {doc.agencyName}
                  </p>
                  <p>
                    <strong>Document Title:</strong>{" "}
                    {doc.docName.split(/(?=[A-Z])/).join(" ")}
                  </p>
                  <p>
                    <strong>Tax ID:</strong> {doc.docTaxID}
                  </p>
                  <Button
                    label="View My Agreement"
                    handleClick={(e) => handleSamplePDF(attnDocs[index])}
                    // isLinkStyle={true}
                  >
                    View Sample Document
                    {/* {doc.docName.split(/(?=[A-Z])/).join(" ")} */}
                  </Button>
                  <br />
                  <Button
                    label={`Sign ${doc.docName.split(/(?=[A-Z])/).join(" ")}`}
                    handleClick={(e) => handleModal(attnDocs[index])}
                  />
                  {/* <a target="_blank" href={messengerDoc}>
                    View {doc.agencyName} - taxID: {doc.docTaxID} - Sample Document
                  </a>
                  <br /> */}
                  {/* <Button label="View Sample Doc" /> */}
                  {/* <a target="_blank" href={messengerDoc}>
                    View {doc.agencyName} - taxID: {doc.docTaxID} - Sample Document
                  </a>
                  <br /> */}
                  {/* <Button
                    label={`Sign ${doc.agencyName} ${doc.docName
                      .split(/(?=[A-Z])/)
                      .join(" ")}`}
                    handleClick={(e) => handleModal(attnDocs[index])}
                  /> */}
                </div>
                <hr />
              </li>
            ))}
          </ul>

          {/* <ul className="no-list-style">
            {attnDocs.map((doc, index) => (
              <li key={index}>
                <div
                  style={{
                    background: "#e3e3e3",
                    padding: ".5rem",
                    borderRadius: ".25rem",
                    marginBottom: ".5rem",
                  }}
                >
                  <a target="_blank" href={messengerDoc}>
                    View {doc.agencyName} - taxID: {doc.docTaxID} - Sample Document
                  </a>
                  <br />
                  <Button
                    label={`Sign ${doc.agencyName} ${doc.docName
                      .split(/(?=[A-Z])/)
                      .join(" ")}`}
                    handleClick={(e) => handleModal(attnDocs[index])}
                  />
                </div>
              </li>
            ))}
          </ul> */}
        </div>
      ) : (
        <div>
          <strong>"Your documents are all up to date"</strong>
        </div>
      )}

      {attnDocs.length === 0 && !messengerParticipationSigned && (
        <UserMessage
          message="Please click next to finalize the process"
          variant="information"
        />
      )}

      {showModal && (
        <Modal
          closeModal={closeModal}
          modalBody={modalBody}
          modalTitle={modalTitle}
          agencyData={agencyData}
        />
      )}
    </div>
  );
}
