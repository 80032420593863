import React, { useState } from "react";

const MessengerInfoConfirmation = ({ agencyData }) => {
  return (
    <>
      {agencyData && (
        <>
          {agencyData.map((agency, index) => (
            <section key={index}>
              <h2>{agency.companyData.name}</h2>
              {agency.licenseData.map((data, index) => (
                <div key={index} >
                  <h4>NPI Information for License {data.licenseNumber}</h4>
                  <div className="license-data">
                    {data.npiList.map((list, index) => (
                      <div key={index} className="npi-list">
                        <div className="keys-col">
                          {Object.keys(list).map((key, index) => (
                            <p key={index} className="list-key">
                              <strong>{key}</strong>
                            </p>
                          ))}
                        </div>
                        <div className="values-col">
                          {Object.values(list).map((value, index) => (
                            <p key={index} className="list-values">
                              {value ? value : "None listed"}
                            </p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          ))}
        </>
      )}
    </>
  );
};

export default MessengerInfoConfirmation;
