import http from "./httpService";
import apiUrl from "../config.json";
import authService from "./authService";

export async function createDoc(title, ownerName, companyName, phoneNumber) {
    const apiEndpoint = apiUrl.apiUrl + "/createDoc";
    const { data: docResponse } = await http.post(apiEndpoint, {
        username: authService.getCurrentUser(), title: title,
        ownerName: ownerName, companyName: companyName, phoneNumber: phoneNumber.replace(/\D/g, "")
    }, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` }
    });
    return docResponse;
}

export async function resendDoc(docID, title, phoneNumber) {
    const apiEndpoint = apiUrl.apiUrl + "/resendDoc";
    const { data: docResponse } = await http.post(apiEndpoint, { docID: docID, title: title, phoneNumber: phoneNumber }, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` }
    });
    return docResponse;
}

export async function checkDoc(docID) {
    const apiEndpoint = apiUrl.apiUrl + "/checkDoc";
    const { data: docResponse } = await http.post(apiEndpoint, { docID: docID }, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` }
    });
    return docResponse;
}

export async function getDoc(docID) {
    const apiEndpoint = apiUrl.apiUrl + "/getDoc";
    const doc = await http.post(apiEndpoint, { username: authService.getCurrentUser(), docID: docID }, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` }
    });
    return doc.data;
}

export async function setFinalDoc(docId, finalDoc) {
    const apiEndpoint = apiUrl.apiUrl + "/setFinalDoc";
    const docResponse = await http.post(apiEndpoint, { docId: docId, finalDoc: finalDoc }, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` }
    });
    return docResponse;
}

export async function getCounterDocs(agencyId, agencyName) {
    const apiEndpoint = apiUrl.apiUrl + "/getCounterDoc";
    const counterDocs = await http.post(apiEndpoint, {
        username: authService.getCurrentUser(), agencyId: agencyId,
        agencyName: agencyName
    }, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` }
    });
    return counterDocs.data.docs;
}

export default {
    createDoc,
    checkDoc,
    resendDoc,
    getDoc,
    getCounterDocs,
    setFinalDoc
}